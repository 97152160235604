.Tappable {
  position: relative;
}

.Tappable:not([disabled]):not([aria-disabled="true"]) {
  cursor: pointer;
}

.AppRoot--keyboard-input .Tappable:focus-visible {
  outline: none;
}

/**
 * active
 * increased specificity to override CellButton styles
 */
.Tappable--active-background.Tappable--active-background.Tappable--active-background {
  background: var(--background_highlighted);
}

.Tappable--active-opacity.Tappable--active-opacity.Tappable--active-opacity {
  opacity: .7;
}

/**
 * hover
 */
.Tappable__hoverShadow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  border-radius: inherit;
}

.Tappable--hover-background > .Tappable__hoverShadow {
  background: var(--background_hover);
}

.Tappable--hover-opacity {
  opacity: .8;
}

/**
 * mouse
 */
.Tappable--mouse {
  transition: opacity .15s ease-out;
}

.Tappable--mouse .Tappable__hoverShadow {
  transition: background-color .15s ease-out;
}

/**
 * iOS tappable
 */
.Tappable--ios {
  position: relative;
  border-radius: 10px;
  transition: background-color .15s ease-out;
}

.Tappable--ios.Tappable--active-background {
  transition: none;
}

/**
 * Android tappable
 */
.Tappable--android {
  position: relative;
  transition: background-color .15s ease-out;
  border-radius: 8px;
}

.Tappable--android .Tappable__waves {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  border-radius: inherit;
  /* Fix for Safari: css animation + border-radius + overflow ignores parent border-radius */
  will-change: transform;
}

.Tappable--android .Tappable__wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  opacity: 0;
  content: '';
  border-radius: 50%;
  background: rgba(127, 127, 127, .1);
  animation: vkui-animation-wave .3s var(--android-easing);
}

/**
 * VKCOM tappable
 */
.Tappable--vkcom {
  transition: background-color .15s ease-out;
  border-radius: 8px;
}

/**
 * overrides
 */
.Tappable--sizeX-compact {
  border-radius: 0;
}

/**
 * Animations
 */
@keyframes vkui-animation-wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: scale(8);
    opacity: 0;
  }
}

.HorizontalScrollArrow {
  position: absolute;
  cursor: pointer;
  user-select: auto;
  top: 0;
  height: 100%;
  padding: 0;
  opacity: 0;
  z-index: 3;
  border: none;
  background-color: transparent;
  transition: opacity .15s;
  transition-timing-function: var(--android-easing);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HorizontalScroll:hover .HorizontalScrollArrow,
.Gallery:hover .HorizontalScrollArrow {
  opacity: .72;
}

.HorizontalScroll:hover .HorizontalScrollArrow:hover,
.Gallery:hover .HorizontalScrollArrow:hover {
  opacity: 1;
}

.HorizontalScrollArrow__icon {
  background-color: var(--modal_card_background);
  color: var(--icon_secondary);
  width: 40px;
  height: 40px;
  border-radius: 24px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .08), 0 0 16px rgba(0, 0, 0, .12);
  display: flex;
  align-items: center;
  justify-content: center;
}

.HorizontalScrollArrow--left {
  padding-left: 16px;
  left: 0;
}

.HorizontalScrollArrow--left .HorizontalScrollArrow__icon {
  transform: rotate(180deg);
}

.HorizontalScrollArrow--right {
  padding-right: 16px;
  right: 0;
}

/*
 * iOS
 */
.HorizontalScrollArrow--ios .HorizontalScrollArrow {
  transition-timing-function: var(--ios-easing);
}

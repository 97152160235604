.FormField {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1px;
  box-sizing: border-box;
  border-radius: 8px;
}

.FormField > * {
  border-radius: inherit;
}

.FormField__after {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
  margin: -1px;
  color: var(--icon_secondary);
  z-index: 2;
}

.ChipsInput .FormField__after {
  z-index: 3;
}

.FormField__border {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--field_border);
  background: var(--field_background);
  content: '';
  box-sizing: border-box;
  z-index: 1;
  transform-origin: left top;
}

.FormItem--error .FormField__border {
  background: var(--field_error_background);
  border-color: var(--field_error_border);
}

.FormItem--valid .FormField__border {
  border-color: var(--field_valid_border);
}

.FormField--disabled {
  opacity: .64;
  cursor: default;
  pointer-events: none;
}

.FormField:not(.FormField--disabled) .FormField__border--hover {
  border-color: var(--icon_tertiary_alpha);
}

.FormField:focus-within .FormField__border,
.FormField *:focus ~ .FormField__border,
.ChipsInput--focused .FormField__border {
  border-color: var(--accent);
  background: var(--field_background);
}

/**
 * sizeY COMPACT
 */
.FormField--sizeY-compact .FormField__after {
  min-width: 36px;
  height: 36px;
}

/**
 * iOS
 */
.FormField--ios {
  border-radius: 10px;
}

@media (min-resolution: 2dppx) {
  .FormField--ios .FormField__border {
    border-radius: 20px;
    transform: scale(.5);
    width: 200%;
    height: 200%;
  }
}

@media (min-resolution: 3dppx) {
  .FormField--ios .FormField__border {
    border-radius: 31.25px;
    transform: scale(.32);
    width: 312.5%;
    height: 312.5%;
  }
}

/**
 * VKCOM
 */
.FormField--vkcom {
  border-radius: 6px;
}

/**
 * ModalCardBase
 */

.ModalCardBase__header + .FormField,
.ModalCardBase__subheader + .FormField {
  margin-top: 16px;
}

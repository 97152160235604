.WriteBarIcon {
  background: none;
  border: 0;
  position: relative;
  color: var(--icon_secondary);
  height: 52px;
  width: 44px;
  cursor: pointer;
  border-radius: 8px;
}

.WriteBarIcon__in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.WriteBarIcon:disabled {
  opacity: .4;
  pointer-events: none;
}

.WriteBarIcon__active {
  opacity: .6;
}

.WriteBarIcon__counter {
  position: absolute;
  top: 6px;
  right: 2px;
  box-shadow: 0 0 0 2px var(--background_content);
}

.WriteBarIcon.WriteBarIcon--send,
.WriteBarIcon.WriteBarIcon--done {
  color: var(--accent);
}

/**
 * iOS
 */
.WriteBarIcon--ios {
  color: var(--writebar_icon);
  border-radius: 10px;
}

.WriteBarIcon--ios.WriteBarIcon--send,
.WriteBarIcon--ios.WriteBarIcon--done {
  width: 48px;
  color: var(--writebar_icon);
}

.WriteBarIcon--ios.WriteBarIcon--send:not(:disabled) {
  color: var(--accent);
}

.WriteBarIcon--ios.WriteBarIcon--send:first-child,
.WriteBarIcon--ios.WriteBarIcon--done:first-child {
  margin-left: -4px;
}

.WriteBarIcon--ios.WriteBarIcon--send:last-child,
.WriteBarIcon--ios.WriteBarIcon--done:last-child {
  margin-right: -4px;
}

/**
 * VKCOM
 */
.WriteBarIcon--vkcom {
  color: var(--icon_secondary);
  border-radius: 10px;
}

.FormItem {
  display: block;
  padding: 12px var(--formitem_padding);
}

.FormItem--removable {
  padding-left: 0;
  padding-right: 0;
}

.FormItem__removable {
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
}

.FormItem__top {
  padding-top: 2px;
  padding-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text_subhead);
}

.FormItem__bottom {
  color: var(--text_secondary);
  padding-top: 8px;
}

.FormItem--error .FormItem__bottom {
  color: var(--field_error_border);
}

.FormItem--valid .FormItem__bottom,
.FormItem--verified .FormItem__bottom {
  color: var(--field_valid_border);
}

/**
 * sizeY COMPACT
 */
.FormItem--sizeY-compact .FormItem__top {
  padding-bottom: 6px;
}

.FormItem--sizeY-compact .FormItem__bottom {
  padding-top: 6px;
}

/**
 * iOS
 */
.FormItem--ios {
  --formitem_padding: 12px;
}

/**
 * CMP:
 * FormLayoutGroup
 */
.FormLayoutGroup--horizontal .FormItem {
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 0;
  min-width: 0;
}

.FormLayoutGroup--horizontal .FormItem + .FormItem {
  margin-left: 24px;
}

.FormLayoutGroup--removable .FormItem--ios.FormItem--withTop {
  margin-top: -28px;
}

.FormLayoutGroup--removable .FormItem--ios.FormItem--withTop.FormItem--sizeY-compact {
  margin-top: -26px;
}

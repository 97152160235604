.ModalPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  pointer-events: none;
}

.ModalPage--desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalPage__in-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  pointer-events: initial;
  transform: translateY(100%);
  transition: transform 320ms var(--android-easing);
}

.ModalRoot--switching .ModalPage__in-wrap {
  pointer-events: none;
}

.ModalPage__in {
  width: 416px;
  max-width: 100%;
  margin: 0 auto;
  background: var(--background_content);
  overflow: visible;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px 12px 0 0;
}

.ModalPage__header {
  width: 100%;
}

.ModalPage__content-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.ModalPage__content {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}

.ModalPage--desktop .ModalPage__content,
.ModalRoot__modal--expandable .ModalPage__content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ModalPage__content-in {
  position: relative;
  padding-bottom: var(--safe-area-inset-bottom);
}

.ModalRoot__modal--collapsed .ModalPage__content,
.ModalRoot__modal--dragging .ModalPage__content {
  overflow: hidden;
  touch-action: pan-y;
}

.ModalPage__footer {
  background: var(--background_content);
  position: relative;
  z-index: 10;
}

.ModalRoot--touched .ModalPage__footer {
  transition: none;
}

/**
 * iOS
 */
.ModalPage--ios .ModalPage__in-wrap {
  transition: transform 400ms var(--ios-easing);
}

.ModalPage--ios .ModalPage__in {
  border-radius: 14px 14px 0 0;
}

/**
 * VKCOM
 */
.ModalPage--vkcom .ModalPage__in {
  border-radius: 8px 8px 0 0;
  width: 448px;
}

/**
 * desktop
 */

.ModalPage--desktop .ModalPage__in-wrap {
  align-items: center;
  opacity: 0;
  transition: opacity 340ms var(--android-easing);
  width: auto;
  height: auto;
  position: relative;
  transform: none;
}

.ModalPage--desktop .ModalPage__in {
  height: auto;
  max-height: 640px;
  box-shadow:
    0 16px 16px rgba(0, 0, 0, .16),
    0 0 8px rgba(0, 0, 0, .12);
  border-radius: 8px;
}

@media (max-height: 672px) {
  .ModalPage--desktop .ModalPage__in {
    max-height: calc(100vh - 32px);
  }
}

/**
 * common
 */

.ModalRoot--touched .ModalPage__in-wrap {
  transition: none;
}

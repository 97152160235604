.Radio {
  display: block;
}

.FormItem .Radio {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--formitem_padding));
}

.Radio__input {
  display: none;
}

.Radio__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Radio__input:disabled ~ .Radio__container {
  opacity: .6;
}

.Radio__icon {
  display: block;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
}

.Radio__icon::after {
  position: absolute;
  content: '';
  display: block;
  border-radius: 50%;
  box-sizing: border-box;
}

.Radio__content {
  display: block;
  flex-grow: 1;
  max-width: 100%;
  color: var(--text_primary);
}

.Radio__description {
  display: block;
  color: var(--text_secondary);
}

.Radio--ios {
  padding: 0 12px;
}

.Radio--ios .Radio__icon {
  width: 22px;
  height: 22px;
  border: 2px solid var(--icon_tertiary);
  margin: 1px 12px 1px 1px;
  transition: border-color .15s var(--ios-easing);
}

.Radio--ios.Radio--sizeY-compact .Radio__icon {
  width: 18px;
  height: 18px;
  margin-right: 11px;
}

.Radio--ios .Radio__input:checked ~ .Radio__container .Radio__icon {
  border-color: var(--accent);
}

.Radio--ios .Radio__icon::after {
  width: 14px;
  height: 14px;
  left: 2px;
  top: 2px;
  transform: scale(.3);
  transition: transform .15s var(--ios-easing);
}

.Radio--ios.Radio--sizeY-compact .Radio__icon::after {
  top: 1px;
  left: 1px;
  width: 12px;
  height: 12px;
}

.Radio--ios .Radio__input:checked ~ .Radio__container .Radio__icon::after {
  background-color: var(--accent);
  transform: scale(1);
}

.Radio--ios .Radio__children {
  margin-top: 11px;
}

.Radio--ios .Radio__children:last-child {
  margin-bottom: 13px;
}

.Radio--ios .Radio__description {
  margin-bottom: 12px;
  margin-top: 1px;
}

.Radio--android {
  padding: 0 16px;
}

.Radio--android .Radio__icon {
  width: 22px;
  height: 22px;
  border: 2px solid var(--icon_tertiary);
  margin: 1px 12px 1px 1px;
  transition: border-color .5s var(--ios-easing);
}

.Radio--android.Radio--sizeY-compact .Radio__icon {
  width: 18px;
  height: 18px;
  margin-right: 11px;
}

.Radio--android .Radio__icon::after {
  width: 14px;
  height: 14px;
  left: 2px;
  top: 2px;
  transform: scale(.3);
  transition: transform .5s var(--ios-easing);
}

.Radio--android.Radio--sizeY-compact .Radio__icon::after {
  top: 1px;
  left: 1px;
  width: 12px;
  height: 12px;
}

.Radio--android .Radio__input:checked ~ .Radio__container .Radio__icon {
  border-color: var(--accent);
}

.Radio--android .Radio__input:checked ~ .Radio__container .Radio__icon::after {
  background-color: var(--accent);
  transform: scale(1);
}

.Radio--android .Radio__children {
  margin-top: 12px;
}

.Radio--android .Radio__children:last-child {
  margin-bottom: 12px;
}

.Radio--android .Radio__description {
  margin-top: 1px;
  margin-bottom: 11px;
}

.Radio--vkcom {
  padding: 0 16px;
}

.Radio--vkcom .Radio__icon {
  width: 16px;
  height: 16px;
  margin: 2px 14px 2px 2px;
  border: 1px solid var(--icon_secondary);
  border-radius: 50%;
  transition: border-color .5s var(--ios-easing);
}

.Radio--vkcom .Radio__icon::after {
  width: 8px;
  height: 8px;
  left: 3px;
  top: 3px;
  transform: scale(.3);
  transition: transform .5s var(--ios-easing);
}

.Radio--vkcom .Radio__input:disabled ~ .Radio__container {
  opacity: .4;
}

.Radio--vkcom .Radio__input:checked ~ .Radio__container .Radio__icon {
  border-color: var(--accent);
}

.Radio--vkcom .Radio__input:checked ~ .Radio__container .Radio__icon::after {
  background-color: var(--accent);
  transform: scale(1);
}

.Radio--vkcom .Radio__children {
  margin-top: 10px;
}

.Radio--vkcom .Radio__children:last-child {
  margin-bottom: 10px;
}

.Radio--vkcom .Radio__description {
  margin-top: 1px;
  margin-bottom: 9px;
}

.Radio--sizeY-compact .Radio__children {
  margin-top: 8px;
}

.Radio--sizeY-compact .Radio__children:last-child {
  margin-bottom: 8px;
}

.Radio--sizeY-compact .Radio__description {
  margin-bottom: 7px;
}

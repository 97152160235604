.Gallery {
  position: relative;
  overflow: hidden;
}

.Gallery__viewport {
  margin: 0;
  height: 100%;
  position: relative;
}

.Gallery--center .Gallery__viewport {
  margin: 0 auto;
}

.Gallery__layer {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100px;
  cursor: grab;
  align-items: stretch;
}

.Gallery__slide {
  width: 100%;
  flex: 0 0 auto;
  max-width: 100%;
  overflow: hidden;
}

.Gallery--custom-width .Gallery__slide {
  width: auto;
}

.Gallery__slide > * {
  width: 100%;
  min-height: 100%;
}

.Gallery__bullets {
  position: absolute;
  bottom: 10px;
  height: 6px;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Gallery__bullets--dark .Gallery__bullet {
  background-color: #000;
}

.Gallery__bullets--light .Gallery__bullet {
  background-color: #fff;
}

.Gallery__bullet {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 0 3px;
  opacity: .56;
}

.Gallery__bullet--active {
  opacity: 1;
}

/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
body[scheme="space_gray"], [scheme="space_gray"] {
  --accent: #71aaeb;
  --accent_alternate: #ffffff;
  --action_sheet_action_foreground: #ffffff;
  --action_sheet_separator: rgba(255, 255, 255, 0.12);
  --activity_indicator_tint: #5d5f61;
  --attach_picker_tab_active_background: #e1e3e6;
  --attach_picker_tab_active_icon: #19191a;
  --attach_picker_tab_active_text: #e1e3e6;
  --attach_picker_tab_inactive_background: #2c2d2e;
  --attach_picker_tab_inactive_icon: #e1e3e6;
  --attach_picker_tab_inactive_text: #76787a;
  --background_content: #19191a;
  --background_highlighted: rgba(255, 255, 255, 0.08);
  --background_hover: rgba(255, 255, 255, 0.04);
  --background_keyboard: #2c2d2e;
  --background_light: #232324;
  --background_page: #0a0a0a;
  --background_suggestions: #2c2d2e;
  --background_text_highlighted: rgba(113, 170, 235, 0.24);
  --button_bot_shadow: #19191a;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #2c2d2e;
  --button_muted_foreground: #e1e3e6;
  --button_outline_border: #e1e3e6;
  --button_outline_foreground: #e1e3e6;
  --button_primary_background: #e1e3e6;
  --button_primary_foreground: #19191a;
  --button_secondary_background: #454647;
  --button_secondary_background_highlighted: rgba(69, 70, 71, 0.60);
  --button_secondary_destructive_background: #454647;
  --button_secondary_destructive_background_highlighted: rgba(69, 70, 71, 0.60);
  --button_secondary_destructive_foreground: #ff5c5c;
  --button_secondary_destructive_foreground_highlighted: rgba(255, 92, 92, 0.60);
  --button_secondary_foreground: #e1e3e6;
  --button_secondary_foreground_highlighted: rgba(225, 227, 230, 0.60);
  --button_tertiary_background: rgba(0, 0, 0, 0.00);
  --button_tertiary_foreground: #e1e3e6;
  --cell_button_foreground: #e1e3e6;
  --content_placeholder_icon: #aaaeb3;
  --content_placeholder_text: #aaaeb3;
  --content_positive_background: rgba(75, 179, 75, 0.15);
  --content_tint_background: #232324;
  --content_tint_foreground: #909499;
  --content_warning_background: #857250;
  --control_background: #454647;
  --control_foreground: #e1e3e6;
  --control_tint: #19191a;
  --control_tint_muted: #909499;
  --counter_primary_background: #ffffff;
  --counter_primary_text: #19191a;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #76787a;
  --counter_secondary_text: #19191a;
  --destructive: #ff5c5c;
  --dynamic_blue: #2688eb;
  --dynamic_gray: #a3adb8;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_purple: #937ff5;
  --dynamic_raspberry_pink: #f060c0;
  --dynamic_red: #ff3347;
  --dynamic_violet: #a393f5;
  --feed_recommended_friend_promo_background: #6d7885;
  --field_background: #232324;
  --field_border: rgba(255, 255, 255, 0.12);
  --field_error_background: #522e2e;
  --field_error_border: #ff5c5c;
  --field_text_placeholder: #76787a;
  --field_valid_border: #4bb34b;
  --float_button_background: #454647;
  --float_button_background_highlighted: #5d5f61;
  --float_button_border: rgba(0, 0, 0, 0.12);
  --float_button_foreground: #aaaeb3;
  --header_alternate_background: #2c2d2e;
  --header_alternate_tab_active_indicator: #e1e3e6;
  --header_alternate_tab_active_text: #e1e3e6;
  --header_alternate_tab_inactive_text: #76787a;
  --header_background: #19191a;
  --header_background_before_blur: #070708;
  --header_background_before_blur_alternate: #070708;
  --header_search_field_background: #363738;
  --header_search_field_tint: #aaaeb3;
  --header_tab_active_background: #5d5f61;
  --header_tab_active_indicator: #71aaeb;
  --header_tab_active_text: #e1e3e6;
  --header_tab_inactive_text: #76787a;
  --header_text: #e1e3e6;
  --header_text_alternate: #e1e3e6;
  --header_text_secondary: rgba(255, 255, 255, 0.60);
  --header_tint: #e1e3e6;
  --header_tint_alternate: #e1e3e6;
  --icon_alpha_placeholder: #e1e3e6;
  --icon_medium: #909499;
  --icon_medium_alpha: rgba(255, 255, 255, 0.48);
  --icon_name: #aaaeb3;
  --icon_outline_medium: #aaaeb3;
  --icon_outline_secondary: #909499;
  --icon_secondary: #76787a;
  --icon_secondary_alpha: rgba(255, 255, 255, 0.36);
  --icon_tertiary: #5d5f61;
  --icon_tertiary_alpha: rgba(255, 255, 255, 0.24);
  --im_attach_tint: #ffffff;
  --im_bubble_border_alternate: rgba(0, 0, 0, 0.00);
  --im_bubble_border_alternate_highlighted: rgba(0, 0, 0, 0.00);
  --im_bubble_button_background: rgba(255, 255, 255, 0.16);
  --im_bubble_button_background_highlighted: rgba(255, 255, 255, 0.24);
  --im_bubble_button_foreground: #e1e3e6;
  --im_bubble_button_outgoing_background: rgba(255, 255, 255, 0.20);
  --im_bubble_gift_background: #857250;
  --im_bubble_gift_background_highlighted: #ab9871;
  --im_bubble_gift_text: #ffffff;
  --im_bubble_gift_text_secondary: #e3d3ac;
  --im_bubble_incoming: #2c2d2e;
  --im_bubble_incoming_alternate: #2c2d2e;
  --im_bubble_incoming_alternate_highlighted: #5d5f61;
  --im_bubble_incoming_expiring: #262b4d;
  --im_bubble_incoming_expiring_highlighted: #404980;
  --im_bubble_incoming_highlighted: #5d5f61;
  --im_bubble_outgoing: #454647;
  --im_bubble_outgoing_alternate: #454647;
  --im_bubble_outgoing_alternate_highlighted: #76787a;
  --im_bubble_outgoing_expiring: #404980;
  --im_bubble_outgoing_expiring_highlighted: #5965b3;
  --im_bubble_outgoing_highlighted: #5d5f61;
  --im_bubble_wallpaper_button_background: #454647;
  --im_bubble_wallpaper_button_background_highlighted: #76787a;
  --im_bubble_wallpaper_button_foreground: #e1e3e6;
  --im_bubble_wallpaper_incoming: #2c2d2e;
  --im_bubble_wallpaper_incoming_highlighted: #5d5f61;
  --im_bubble_wallpaper_outgoing: #454647;
  --im_bubble_wallpaper_outgoing_highlighted: #5d5f61;
  --im_forward_line_tint: rgba(255, 255, 255, 0.24);
  --im_reply_separator: #ffffff;
  --im_service_message_text: #76787a;
  --im_text_name: #71aaeb;
  --im_toolbar_separator: #aaaeb3;
  --im_toolbar_voice_msg_background: #454647;
  --image_border: rgba(255, 255, 255, 0.08);
  --input_background: #2c2d2e;
  --input_border: #2c2d2e;
  --landing_background: #19191a;
  --landing_field_background: #232324;
  --landing_field_border: rgba(0, 0, 0, 0.00);
  --landing_field_placeholder: #aaaeb3;
  --landing_login_button_background: #e1e3e6;
  --landing_login_button_foreground: #19191a;
  --landing_primary_button_background: #e1e3e6;
  --landing_primary_button_foreground: #19191a;
  --landing_secondary_button_background: rgba(255, 255, 255, 0.15);
  --landing_secondary_button_foreground: #ffffff;
  --landing_snippet_border: rgba(255, 255, 255, 0.15);
  --landing_tertiary_button_background: rgba(0, 0, 0, 0.00);
  --landing_tertiary_button_foreground: #ffffff;
  --landing_text_primary: #e1e3e6;
  --landing_text_secondary: #76787a;
  --landing_text_title: #ffffff;
  --like_text_tint: #ff5c5c;
  --link_alternate: #ffffff;
  --loader_background: #19191a;
  --loader_tint: #76787a;
  --loader_track_fill: #454647;
  --loader_track_value_fill: #ffffff;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #2c2d2e;
  --modal_card_background: #2c2d2e;
  --modal_card_border: rgba(255, 255, 255, 0.12);
  --modal_card_header_close: #ffffff;
  --music_playback_icon: #ffffff;
  --overlay_status_background: #19191a;
  --overlay_status_foreground: #e1e3e6;
  --overlay_status_icon: #ebedf0;
  --panel_tab_active_background: #454647;
  --panel_tab_active_text: #e1e3e6;
  --panel_tab_inactive_text: #76787a;
  --placeholder_icon_background: rgba(255, 255, 255, 0.08);
  --placeholder_icon_background_opaque: #232324;
  --placeholder_icon_foreground_primary: #909499;
  --placeholder_icon_foreground_secondary: #5d5f61;
  --placeholder_icon_tint: #ffffff;
  --poll_option_background: #ffffff;
  --primary_overlay_badge: rgba(255, 255, 255, 0.40);
  --search_bar_background: #19191a;
  --search_bar_field_background: #363738;
  --search_bar_field_tint: #aaaeb3;
  --search_bar_segmented_control_tint: #909499;
  --secondary_overlay_badge: rgba(255, 255, 255, 0.15);
  --segmented_control_bar_background: #19191a;
  --segmented_control_tint: #76787a;
  --selection_off_icon: #5d5f61;
  --separator_alpha: rgba(255, 255, 255, 0.12);
  --separator_alternate: #232324;
  --separator_common: #363738;
  --skeleton_foreground_from: #232324;
  --skeleton_foreground_to: #2c2d2e;
  --skeleton_shimmer_from: #19191a;
  --skeleton_shimmer_to: #19191a;
  --snippet_background: #232324;
  --snippet_border: rgba(255, 255, 255, 0.15);
  --snippet_icon_tertiary: #5d5f61;
  --splashscreen_icon: #2c2d2e;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.00);
  --stories_create_button_background_from: #363738;
  --stories_create_button_background_to: #363738;
  --stories_create_button_foreground: #c4c8cc;
  --stories_create_button_icon_background: #454647;
  --stories_skeleton_loader_background: #454647;
  --switch_ios_off_border: #2c2d2e;
  --tabbar_active_icon: #ffffff;
  --tabbar_background: #2c2d2e;
  --tabbar_inactive_icon: #76787a;
  --tabbar_tablet_active_icon: #71aaeb;
  --tabbar_tablet_background: #232324;
  --tabbar_tablet_inactive_icon: #76787a;
  --tabbar_tablet_text_primary: #e1e3e6;
  --tabbar_tablet_text_secondary: #76787a;
  --text_action_counter: #aaaeb3;
  --text_link: #71aaeb;
  --text_link_highlighted_background: #71aaeb;
  --text_muted: #c4c8cc;
  --text_name: #e1e3e6;
  --text_placeholder: #aaaeb3;
  --text_primary: #e1e3e6;
  --text_secondary: #76787a;
  --text_subhead: #909499;
  --text_tertiary: #5d5f61;
  --toolbar_attach_background_from: #76787a;
  --toolbar_attach_background_to: #5d5f61;
  --vk_connect_button_primary_background: #e1e3e6;
  --vk_connect_button_primary_foreground: #19191a;
  --vk_connect_button_secondary_background: #454647;
  --vk_connect_button_secondary_foreground: #e1e3e6;
  --writebar_icon: #e1e3e6;
}
/* stylelint-enable */

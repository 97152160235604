.List--dragging .Cell:not(.Cell--dragging) {
  transition: transform .3s ease;
  pointer-events: none;
}

.Cell--dragging {
  background-color: var(--background_content);
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  z-index: 3;
}

.Cell--selectable.Cell--disabled {
  opacity: .6;
}

.Cell__checkbox,
.Cell__dragger {
  position: relative;
  flex-shrink: 0;
  margin-right: 16px;
}

.Cell__dragger {
  z-index: 2;
}

.Cell__content {
  max-width: 100%;
  min-width: 0;
}

.Cell--removable .Cell__content {
  flex-grow: 1;
  padding-right: 0;
  padding-left: 0;
}

/**
 * iOS
 */
.Cell--ios .Cell__dragger {
  margin-right: 0;
  margin-left: 12px;
}

.Cell--ios .Cell__checkbox {
  margin-right: 12px;
}

/**
 * CMP:
 * FormItem
 */
.FormItem > .Cell {
  margin: 0 calc(-1 * var(--formitem_padding));
}

.TabbarItem {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tabbar_inactive_icon);
  text-decoration: none;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  height: var(--tabbar_height);
  position: relative;
}

.Tabbar .TabbarItem {
  flex-shrink: 0;
  max-width: 100%;
  min-width: 0;
  flex-grow: 1;
  flex-basis: 0;
}

.TabbarItem--selected {
  color: var(--tabbar_active_icon);
}

.TabbarItem__tappable {
  position: absolute;
}

.TabbarItem__in {
  display: flex;
  padding: 0 2px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  height: 100%;
  overflow: hidden;
}

.Tabbar--l-vertical .TabbarItem--text .TabbarItem__in {
  position: relative;
  top: 2px;
}

.Tabbar--l-vertical .TabbarItem__in {
  flex-direction: column;
}

.Tabbar--l-horizontal .TabbarItem__in {
  flex-direction: row;
}

.TabbarItem__icon {
  position: relative;
}

.TabbarItem__label .Counter {
  position: absolute;
  top: -2px;
  right: -9px;
}

.TabbarItem__label .Badge {
  position: absolute;
  top: -2px;
  right: -4px;
}

.TabbarItem__text {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Tabbar--l-vertical .TabbarItem__text {
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 2px;
}

.Tabbar--l-horizontal .TabbarItem__text {
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
}

/**
 * Android
 */

.TabbarItem--android .TabbarItem__tappable {
  width: calc(100% + 16px);
  padding-bottom: calc(100% + 16px);
  border-radius: 50%;
}

.TabbarItem--android:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.TabbarItem--android:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/**
 * iOS
 */

.TabbarItem--ios .TabbarItem__tappable {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.TabbarItem--ios .TabbarItem__in {
  transition: transform .07s var(--ios-easing);
}

.TabbarItem--ios .TabbarItem__tappable--active + .TabbarItem__in {
  transform: scale(.96);
}

.Removable__content {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: var(--formitem_padding);
}

.Removable__action {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
}

.Removable--start .Removable__action {
  align-self: flex-start;
}

.FormItem--removable.FormItem--withTop .Removable__action,
.FormLayoutGroup--removable .FormItem--withTop ~ .Removable__action {
  top: 28px;
}

.FormItem--sizeY-compact.FormItem--removable.FormItem--withTop .Removable__action,
.FormLayoutGroup--sizeY-compact.FormLayoutGroup--removable .FormItem--withTop ~ .Removable__action {
  top: 26px;
}

/**
 * iOS
 */
.Removable--ios {
  overflow: hidden;
}

.Removable__remove {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 15px;
  line-height: 45px;
  color: var(--white);
  background-color: var(--destructive);
  border: none;
  border-radius: 0;
}

.Removable__remove-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .Removable--ios .Removable__content {
    transition: transform .6s var(--ios-easing);
  }
}

.Removable__toggle {
  display: block;
  width: 44px;
  height: 44px;
  margin: 0 2px;
  background: none;
}

.Removable__toggle-in {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
}

.Removable__toggle-in::after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: var(--destructive);
  border-radius: 50%;
}

.Removable__toggle-in::before {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 2px;
  background-color: var(--white);
}

.Removable--ios .Removable__content {
  padding-left: 0;
  padding-right: var(--formitem_padding);
}

.FormLayoutGroup--removable .Removable__content {
  flex-wrap: wrap;
}

.FormLayoutGroup--removable .FormItem--ios.FormItem--withTop ~ .Removable__offset {
  order: -1;
  display: block;
  width: 100%;
  height: 28px;
}

.FormLayoutGroup--removable.FormLayoutGroup--sizeY-compact .FormItem--ios.FormItem--withTop ~ .Removable__offset {
  height: 26px;
}

.Button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  min-height: 28px;
  margin: 0;
  padding: 0;
  user-select: none;
  border-radius: 8px;
  max-width: 100%;
}

.Button--stretched {
  display: block;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.Button__in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: inherit;
  padding: 1px 16px;
  text-align: center;
  box-sizing: border-box;
}

.Button--aln-left .Button__in {
  justify-content: flex-start;
  text-align: left;
}

.Button--aln-right .Button__in {
  justify-content: flex-end;
  text-align: right;
}

.Button[disabled] {
  opacity: .4;
}

.Button--lvl-primary[disabled],
.Button--lvl-secondary[disabled],
.Button--lvl-tertiary[disabled],
.Button--lvl-outline[disabled] {
  opacity: .64;
}

.Button__before:not(:last-child) {
  margin-left: -4px;
  margin-right: 6px;
}

.Button__after:not(:first-child) {
  margin-left: 6px;
}

.Button__content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Button__content--caps {
  transform: translateY(0);
}

.Button__spinner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.Button__spinner + .Button__in {
  visibility: hidden;
}

.Button--lvl-primary {
  background-color: var(--button_primary_background);
  color: var(--button_primary_foreground);
}

.Button--lvl-secondary {
  background-color: var(--button_secondary_background);
  color: var(--button_secondary_foreground);
}

.Button--lvl-tertiary {
  background-color: var(--button_tertiary_background);
  color: var(--button_tertiary_foreground);
}

.Button--lvl-commerce {
  background-color: var(--button_commerce_background);
  color: var(--button_commerce_foreground);
}

.Button--lvl-outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--button_outline_border);
  color: var(--button_outline_foreground);
}

.Button--lvl-destructive {
  background-color: var(--destructive);
  color: var(--white);
}

.Button--lvl-overlay_primary {
  background-color: var(--media_overlay_button_background);
  color: var(--media_overlay_button_foreground);
}

.Button--lvl-overlay_secondary {
  background-color: rgba(255, 255, 255, .2);
  color: var(--white);
}

.Button--lvl-overlay_outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--white);
  color: var(--white);
}

.Button--sz-l .Button__before:not(:last-child) {
  margin-right: 8px;
}

.Button--sz-l .Button__after:not(:first-child) {
  margin-left: 8px;
}

.Button--sz-l .Button__in {
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.Button:not(.Button--sz-s) .Button__content {
  transform: translateY(-1px);
}

/**
 * iOS
 */
.Button--ios {
  min-height: 26px;
  border-radius: 10px;
}

/**
 * VKCOM
 */
.Button--vkcom {
  min-height: 26px;
  border-radius: 4px;
}

.Button--vkcom.Button--lvl-secondary .Icon,
.Button--vkcom.Button--lvl-tertiary .Icon,
.Button--vkcom.Button--lvl-outline .Icon {
  opacity: .72;
}

/**
 * sizeY COMPACT
 */
.Button--sz-m {
  min-height: 32px;
}

.Button--sz-l {
  min-height: 36px;
}

/**
 * sizeY REGULAR
 */
.Button--sizeY-regular {
  min-height: 30px;
}

.Button--sizeY-regular.Button--sz-m {
  min-height: 36px;
}

.Button--sizeY-regular.Button--sz-l {
  min-height: 44px;
}

/**
 * CMP:
 * Banner
 */
.Banner__actions > .Button {
  margin-top: 12px;
  margin-right: 16px;
  margin-bottom: 6px;
}

.Banner__actions > .Button--lvl-tertiary {
  margin: 4px 16px 2px -16px;
}

.Banner__actions > .Button--lvl-tertiary:first-child {
  margin-top: 4px;
}

.Banner__actions > .Button:not(.Button--lvl-tertiary) + .Button:not(.Button--lvl-tertiary) {
  margin-top: 0;
}

.Banner__actions > .Button:not(.Button--lvl-tertiary) + .Button--lvl-tertiary {
  margin-top: 2px;
  margin-left: -16px;
}

.Banner__actions > .Button--lvl-tertiary + .Button--lvl-tertiary {
  margin-top: 2px;
}

/**
 * CMP:
 * ModalCardBase
 */
.ModalCardBase__actions .Button {
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
}

.ModalCardBase__actions--v .Button {
  margin-left: 0;
  margin-right: 0;
}

.ModalCardBase__actions--v .Button + .Button {
  margin-top: 12px;
}

/**
 * CMP:
 * RichCell
 */
.RichCell__actions .Button + .Button {
  margin-left: 8px;
}

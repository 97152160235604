.Textarea {
  position: relative;
}

.Textarea__el {
  margin: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  resize: none;
  appearance: none;
  line-height: 20px;
  font-size: 16px;
  color: var(--text_primary);
  padding: 11px;
  max-height: 196px;
  border: none;
  z-index: 2;
  position: relative;
  background: transparent;
}

.Textarea--sizeY-compact .Textarea__el {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 15px;
}

.Textarea__el::placeholder {
  color: var(--field_text_placeholder);
}

.Textarea__el:disabled {
  opacity: .4;
}

.Textarea__el:disabled::placeholder {
  color: var(--text_secondary);
}

.ModalRoot {
  width: 100%;
  height: 100%;
}

.ModalRoot__mask {
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .4);
  user-select: none;
  transition: opacity 167ms ease-out;
  animation: vkui-animation-fadeIn .32s ease;
}

.ModalRoot--touched .ModalRoot__mask {
  transition: none;
}

.ModalRoot--switching .ModalRoot__mask {
  /** Во время автоматической анимации смены модалок с фоном взаимодействовать нельзя */
  pointer-events: none;
}

.ModalRoot__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

:not(.ModalRoot--desktop).ModalRoot--ios .ModalRoot__viewport {
  top: var(--safe-area-inset-top);
}

:not(.ModalRoot--desktop).ModalRoot--vkapps.ModalRoot--android .ModalRoot__viewport {
  top: var(--panelheader_height_android);
}

:not(.ModalRoot--desktop).ModalRoot--vkapps.ModalRoot--ios .ModalRoot__viewport {
  top: calc(var(--safe-area-inset-top) + var(--panelheader_height_ios));
}

.ModalRoot__modal {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
}

@keyframes vkui-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
